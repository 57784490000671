import React, { Component } from 'react';

import LoyaltyComponent from '../../components/account/Loyalty';

class Loyalty extends Component {
  render() {
    const { site } = this.props;

    return <LoyaltyComponent site={site} />;
  }
}

export default Loyalty;
