import React, { Component } from 'react';

import AccountFrame from './AccountFrame';
import withAccount from '../../containers/plugins/withAccount';

class Loyalty extends Component {
  constructor(props) {
    super(props);

    this.initWidgets = this.initWidgets.bind(this);
    this.refreshWidgets = this.refreshWidgets.bind(this);
  }

  componentDidMount() {
    this.initWidgets();
  }

  componentDidUpdate() {
    this.refreshWidgets();
  }

  initWidgets() {
    if (typeof window.yotpoWidgetsContainer !== 'undefined') {
      window.yotpoWidgetsContainer.initWidgets();
      this.refreshWidgets();
    }
  }

  refreshWidgets() {
    if (typeof window.yotpo !== 'undefined') {
      window.yotpo.refreshWidgets();
    }
  }

  render() {
    const { account } = this.props;

    const accountId = account?.id?.split('/').slice(-1)[0] || null;
    const accountEmail = account?.email || null;

    return (
      <AccountFrame title={'Earn Points'} {...this.props}>
        <div
          id="swell-customer-identification"
          data-authenticated={true}
          data-email={accountEmail}
          data-id={accountId}
        />
        <div
          className="yotpo-widget-instance"
          data-yotpo-instance-id="297355"
        />
      </AccountFrame>
    );
  }
}

export default withAccount(Loyalty);
